<template>
  <!-- 设备故障 -->
  <div class="EquipmentGuzh">
    <div class="top">
      <el-input
        type="text"
        placeholder="请输入场地名称或设备编码"
        v-model="searchtext"
        maxlength="20"
        show-word-limit
        :disabled="!issearch"
      ></el-input>
      <el-button :type="issearch?'primary':'warning'" size="mini">
        <span v-if="issearch" @click="guzhangList(1)">查询</span>
        <span v-else @click="quxiaoSearch">取消查询</span>
      </el-button>
    </div>
    <el-table border :data="tableData" style="width: 100%;margin-top:20px;max-height:70vh;overflow-Y:scroll">
      <el-table-column label=" 场地名称" prop="address_name"></el-table-column>
      <el-table-column label="设备编码" prop="device_number"></el-table-column>
      <el-table-column label="端口号" prop="device_port"></el-table-column>
      <el-table-column label="故障信息描述" prop="error_msg"></el-table-column>
      <el-table-column label="故障级别" prop="error_type"></el-table-column>
      <el-table-column label="是否解决">
        <template #default="scope">
          <el-switch
            v-model="scope.row.status"
            active-color="#13ce66"
            @change="switchChangeGz(scope.row)"
          ></el-switch>
        </template>
      </el-table-column>
      <el-table-column label=" 故障时间" prop="created_at" sortable></el-table-column>
    </el-table>
  </div>
</template>
<script>
import { defineComponent, ref, watch } from "vue";
import { errorLog, errortongguo } from "../../request/api";
export default {
  props: ["page", "pagepage"],
  watch: {
    page() {
      this.guzhangList();
    },
    pagepage() {
      this.guzhangList();
    }
  },
  data() {
    return {
      tableData: [],
      tableDataCopy: [],
      searchtext: "",
      issearch: true,
      form: [
        {
          aa: 11
        }
      ],
      input: ref(""),
      pgaetotal: 0
    };
  },
  async created() {
    this.guzhangList();
    this.pgaetotal = await this.guzhangList();
    this.pgaetotal?this.$emit("pagetotalClick", this.pgaetotal):'';
  },
  methods: {
    quxiaoSearch(){
      this.issearch=true;
      this.searchtext='';
      this.tableData=this.tableDataCopy;
      this.$emit("pagetotalClick", this.pgaetotal)
    },
    switchChangeGz(item) {
      errortongguo({
        id: item.id,
        status: item.status == true ? 1 : 0
      }).then(res => {
        if (res.code == 200) {
          this.$message({
            type: res.code == 200 ? "success" : "info",
            message: res.msg
          });
        }
      });
    },
    guzhangList(val) {
      val ? (this.issearch = false) : "";
      return new Promise((resolve, reject) => {
        errorLog({
          page: this.page,
          page_size: this.pagepage,
          keyword: this.searchtext
        }).then(res => {
          resolve(res.total);
          val? this.$emit("pagetotalClick", res.total):'';
          val ? "" : (this.tableDataCopy = res.list);
          this.tableData = res.list;
          this.tableData.forEach(item => {
            item.status = item.status == 1;
          });

        });
      });
    }
  }
};
</script>
<style lang="less" scoped>
.el-input {
  width: 300px;
  margin-right: 10px;
}
.iconfont {
  font-size: 18px;
}
.top {
  display: flex;
  justify-content: flex-end;
}
</style>
